$.validator.addMethod('emailExt', value => {
  const patternEmail = /^[A-z0-9._%+-]+@[A-z0-9.-]+\.[A-z]{2,4}$/.test(value);
  return patternEmail;
}, 'Your E-mail is wrong');

$('.js-validation').each((i, el) => $(el).validate());

$('.js-validator-email').rules('add', {
  emailExt: true,
});
