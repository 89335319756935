require("@rails/ujs").start()
// require("@rails/activestorage").start()
// require("channels")

// add all images to manifest.js from app/packs/img folder
const images = require.context('../img', true)

// load Icomoon custom icon font
// import "../fonts/icomoon/style"

// load main app.sass to compile css
import "../stylesheets/app"

// load main app.js to compile js
import "./app"

// import $ from 'jquery';
global.$ = jQuery;
