const $fullpage = $('.fullpage');
const $section = $('.section');
const initFullPage = $(window).width() > 1000 && $(window).height() > 460;

if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
  $fullpage.removeAttr('id');
}

const fullPageInit = () => {
  if (initFullPage) {
    $('.section__scroll').show();

    $('.header').addClass('header_fullpage');

    $section.removeAttr('id');

    $fullpage.fullpage({
      css3: false,
      navigation: true,
      responsiveWidth: 1000,
      responsiveHeight: 460,
      fixedElements: '.footer',
      anchors: ['section1',
        'section2',
        'section3',
        'section4',
        'section5',
      ],
      menu: '#nav',
    });
  }
};

const mobilefullPageInit = () => {
  const toggleSection = () => {
    $section.each((i, el) => {
      $(el).hide();
      setTimeout(() => {
        const hash = window.location.hash;
        const checkHash = hash === `#${$(el).attr('id')}`;

        $('.navigation__list .js-show-section').each((i, link) => {
          $(link).removeClass('active');
          if (hash === $(link).attr('href')) {
            $('.navigation__item').removeClass('active');
            $(link).addClass('active').closest('.navigation__item').addClass('active');
          }
        });

        hash !== '' ? checkHash && $(el).show() : $('#section1').show();
      }, 1);
    });
  }

  toggleSection();

  $('.js-show-section').on('click', () => toggleSection());
};

$fullpage.is('#fullpage') && initFullPage ? fullPageInit() : mobilefullPageInit();

if ($fullpage.is('#fullpage') && initFullPage  && $(window).height() < 650) {
  $('.js-scroll-list').each(function () {
    const $this = $(this);
    $this.parent().addClass('active');

    function autoScrollUp(){
      $this
        .css({ top: 0 })
        .animate(
          { top: -$this.outerHeight() / 2 },
          14000,
          'linear',
          autoScrollUp
        );
    }

    $this.html($this.html() + $this.html());

    autoScrollUp();
  });
}
