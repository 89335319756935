import 'jquery-validation';
import 'jquery-mask-plugin';
import "./vendor/scrolloverflow.min.js";
import "./vendor/jquery.fullPage.min.js";

import "./components/Fullpage";
import "./components/Select";
import "./components/Validation";
import "./components/MaskInput";
import "./components/AnimationText";
import "./components/Modal";
import "./components/CountDown";
