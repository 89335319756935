setTimeout(function() {
  $('.animation-text').each(function() {
    let counter = 0;

    const $this = $(this);
    const $list = $(this).find('.animation-text__list');

    const listHeight = $list.outerHeight();
    const listWidth = $list.outerWidth();
    const itemsLength = $(this).find('.animation-text__list li').length;
    const itemsHeight = listHeight / itemsLength;
    const statPosition = itemsHeight - listHeight;

    $this
      .height(itemsHeight)
      .width(listWidth)
      .find('.animation-text__list')
      .css({
        height: listHeight,
        bottom: statPosition
      });

    const step = function() {
      if (counter < itemsLength) {
        counter++;

        setTimeout(function() {
          $list.animate({
              bottom: statPosition + (itemsHeight * counter)
            },
            1000,
            'linear',
            step
          );
        }, 1000);
      } else {
        counter = 0;

        setTimeout(function() {
          $list.animate({
              bottom: statPosition + (itemsHeight * counter)
            },
            0,
            'linear',
            step
          );
        }, 0);
      }
    };

    $list.html($list.html() + $list.html());

    step();
  });
}, 0);
