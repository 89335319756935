$('select').each(function() {
  const $this = $(this),
    numberOfOptions = $(this).children('option').length;

  $this.addClass('s-hidden');
  $this.wrap('<div class="select"></div>');
  $this.after('<div class="select__item"></div>');
  const $styledSelect = $this.next('div.select__item');
  $styledSelect.text($this.children('option').eq(0).text());

  const $list = $('<ul />', {
    'class': 'select__options'
  }).insertAfter($styledSelect);

  for (let i = 0; i < numberOfOptions; i++) {
    $('<li />', {
      text: $this.children('option').eq(i).text(),
      rel: $this.children('option').eq(i).val()
    }).appendTo($list);
  }

  const $listItems = $list.children('li');

  $styledSelect.click(function(e) {
    e.stopPropagation();
    $(this).toggleClass('active').next('ul.select__options').toggle();
  });

  $listItems.click(function(e) {
    e.stopPropagation();
    $styledSelect.text($(this).text()).removeClass('active').addClass('changed');
    $this.val($(this).attr('rel'));
    $list.hide();
  });

  $(document).click(function() {
    $styledSelect.removeClass('active');
    $list.hide();
  });
});
