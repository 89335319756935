function countDownTimer(date) {
  const elem = $('#countDown');
  const futureTime = new Date(date).getTime();

  setInterval(function() {
    let timeLeft = Math.floor((futureTime - new Date().getTime()) / 1000);
    let days = Math.floor(timeLeft / 86400);
    timeLeft -= days * 86400;
    let hours = Math.floor(timeLeft / 3600) % 24;
    timeLeft -= hours * 3600;

    const timeString = "<span class='days'>" + days + " Days " + "</span>" +
      "<span class='hours'>" + hours + " Hours " + "</span>";
    elem.html(timeString);
  }, 1000);
}

countDownTimer('March 8, 2020');
